import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { appAnalytics } from "./config/index";
import Card from "./pages/card";
import Company from "./pages/company";
import { Brand } from "./pages/brand";

const analytics = appAnalytics;

function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<Brand />} />
        <Route path="/:id" element={<Card />} />
        <Route path="/organization/:id" element={<Company />} />
        <Route path="/code/:id" element={<Company />} />
      </Routes>
    </Router>
  );
}

export default App;
